import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { CamelCaseToProperPipe } from '../../../pipes/camel-case-to-proper-case';
import { CustomColumnDisplayPipe } from '../../../pipes/custom-column-display';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'search-parameters',
  templateUrl: './search-parameters.component.html',
  styleUrls: ['./search-parameters.component.css'],
  providers: [CamelCaseToProperPipe, CustomColumnDisplayPipe],
  encapsulation: ViewEncapsulation.None,
})
export class SearchParametersComponent implements OnInit {
  @Input()
  inputParams: any = {
    searchTags: Array,
  };
  @Input() savedSearchName: string;

  @Output() onClearSearchParam: EventEmitter<any> = new EventEmitter();

  nonClearingParams = ['pageNumber', 'savedSearchName', 'pageSize'];
  arrayOfSpecialObjectsParams = ['selectedCustomColumns', 'selectedModules'];
  truncParamsTooltips: Record<string, any> = {};
  customColsStrArr = [];
  labelsCount: Record<string, number> = {};

  constructor(
    private camelCaseToProperPipe: CamelCaseToProperPipe,
    private customColumnDisplayPipe: CustomColumnDisplayPipe,
    public pubMethods: publicMethods,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.savedSearchName) {
      this.inputParams.searchTags.unshift({
        param: 'savedSearchName',
        value: this.savedSearchName,
      });
    }
  }

  ngAfterContentChecked(): void {
    // need this otherwise NG0100 error
    this.cdr.detectChanges();
  }

  formatParamName(name: string): string {
    if (name.startsWith('selected')) {
      return this.camelCaseToProperPipe.transform(name.slice(8));
    } else if (name.endsWith('Selected')) {
      return this.camelCaseToProperPipe.transform(name.slice(0, -8));
    }
    return this.camelCaseToProperPipe.transform(name);
  }

  formatParamValue(param: {
    paramObj: Record<string, any>;
    truncate?: boolean;
    limit?: number;
  }): string {
    /**
     * values like '["Active Companies"]' need parsing.
     * default limit is 13 for 1trillion energy values
     */

    const { paramObj, truncate = false, limit = 13 } = param;

    const specialParams = ['selectedTerritory', ...this.nonClearingParams];

    if (specialParams.includes(paramObj.param)) return paramObj.value;

    // format custom columns
    if (paramObj.param === 'selectedCustomColumns') {
      this.customColsStrArr = this.customColumnDisplayPipe.transform(
        paramObj.value
      );

      return this.truncateText(this.customColsStrArr, limit, paramObj.param);
    }

    if (paramObj.param === 'selectedModules') {
      const parsed = JSON.parse(paramObj.value);

      const names = parsed.filter(obj => obj?.checked).map(obj => obj?.name);
      return this.truncateText(names, 13, paramObj.param);
    }

    if (paramObj.value && Number(paramObj.value)) {
      // format the large number values but shows whole val in tooltip
      this.truncParamsTooltips[paramObj.param] = paramObj.value;
      return this.formatLongNumbers(Number(paramObj.value));
    }

    if (paramObj.value.includes('[')) {
      let parsed: string;

      if (this.pubMethods.hasNestedObjectInJson(paramObj.value)) {
        //e.g aira multiselect are nested
        parsed = JSON.parse(paramObj.value)?.value;
      } else {
        parsed = JSON.parse(paramObj.value).join(', '); // comma and space
      }

      return truncate
        ? this.truncateText(parsed, limit, paramObj.param)
        : parsed;
    }

    if (paramObj.value.includes('{')) {
      const parsed = JSON.parse(paramObj.value);
      if (paramObj.param === 'selectedView') {
        // selectedView option
        return parsed.name || '';
      } else {
        // other conditions such as airaQuestionFilters
        return parsed.value
          ? this.truncateText(parsed.value, limit, paramObj.param)
          : '';
      }
    }

    return truncate
      ? this.truncateText(paramObj.value, limit, paramObj.param)
      : paramObj.value;
  }

  shouldShowLabelsCount(paramName: string): boolean {
    return this.arrayOfSpecialObjectsParams.includes(paramName);
  }

  truncateText(
    value: string | string[],
    limit: number,
    paramName: string
  ): string {
    if (Array.isArray(value)) {
      if (!value.length) return '';

      if (this.arrayOfSpecialObjectsParams.includes(paramName)) {
        /**
         * if multiple custom cols, show one full custom attribute ie. name and value
         * and the rest be truncated and shown in tooltip
         */
        // console.log(value);
        this.labelsCount[paramName] = value.length;
        this.truncParamsTooltips[paramName] = value
          .map((str, i, arr) => {
            const separator = i === arr.length - 1 ? '\n' : '\u2022\n';
            return `${i + 1}. ${str} \n ${separator}`;
          })
          .join('');
        return value[0] + (value.length > 1 ? '...' : '');
      } else {
        this.truncParamsTooltips[paramName] = value;
        return (
          value.slice(0, 3).join(', ') + `${value.length > 3 ? '...' : ''}`
        );
      }
    }

    if (typeof value === 'string' && value?.length > limit) {
      /**
       * chunk below for certain values like top sic codes that are only 1 letter each and we don't want to unnecessarily truncate them.
       */

      this.truncParamsTooltips[paramName] = value;

      const chunkArr = value.split(',');
      // See if any chunk has a length > 4 (longest sics are 4 digits)
      if (chunkArr.some(chunk => chunk?.length > 4)) {
        // If so, return the 1st word of the 1st chunk eg. Active in Active Companies
        return chunkArr[0].split(/\s/)[0] + '...';
      }
      // Otherwise join the first 3 chunks + '...'(upto 3 letters)
      return chunkArr.slice(0, 3).join() + '...';
    }
    return value as string;
  }

  formatLongNumbers(numValue: number): string {
    let valueFormatted: string;

    if (numValue >= 1000000000) {
      valueFormatted = parseFloat((numValue / 1000000000).toFixed(1)) + 'b';
    } else if (numValue >= 1000000) {
      valueFormatted = parseFloat((numValue / 1000000).toFixed(1)) + 'm';
    } else if (numValue >= 1000) {
      valueFormatted = parseFloat((numValue / 1000).toFixed(1)) + 'k';
    } else {
      valueFormatted = `${numValue}`;
    }
    return valueFormatted;
  }

  clearParameterButtonWasClicked(clickedEntry): void {
    this.onClearSearchParam.emit([clickedEntry]);
  }

  excludeClearing(param: string): boolean {
    return !this.nonClearingParams.includes(param);
  }
}
