@defer (when airaResponses.length) {
<div class="aira-responses-container">
    @for (response of airaResponses; track $index) {
        @if(response.display_schema?.properties){           
            @for (property of response.display_schema.properties | keyvalue; track $index) { 
                <div class="aira-insights-container" [ngClass]="shouldSpanTwoColumns(property.value.display_type, response.answer[property.key])"> 
                    <aira-switch-cases [displaySchema]="property.value" [displayData]="response.answer[property.key]" [completeAnswer]="response" [moduleSectionId]="moduleSectionId"/>        
                </div>
            }
        }
        @else {
            <div class="aira-insights-container" [ngClass]="shouldSpanTwoColumns(response.display_schema.display_type, response.answer)">               
                <aira-switch-cases [displaySchema]="response.display_schema" [completeAnswer]="response" [displayData]="response.answer" [moduleSectionId]="moduleSectionId"/>
            </div>
        }
    }
</div>
}
