<div class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="downloadModalLabel">Download These Organisations</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-12">
                        <ng-container *ngIf=" selectedCompanyCount < userRemainingDownloads; else elseBlock">
                            <p>This will use <strong>{{ selectedCompanyCount }}</strong> of your available {{ userRemainingDownloads }} export credits.</p>
                            
                            <div class="mb-3">                        
                            <details open>
                                <summary>Current CSV Download Headers</summary>
                                <ul class="bg-light max-height-20vh mb-1">
                                    @for (item of userDownloadFormat; track $index) {
                                        <li>{{item}}</li>
                                    }
                                </ul>
                            </details>
                            <span class="">Above are your currently set column headers for download. You can edit these headers in the <a
                                    href="/account">accounts settings.</a></span>
                            </div>
                            <div class="text-center">
                            <button type="button" class="rounded-btn-base rounded-btn-dark-hover control-buttons" (click)="generateCSVDownloadUrl()" [attr.disabled]="isLoading.loadingDownloadUrl || downloadUrl || downloadRequestId ? 'disabled': null"><i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingDownloadUrl"></i> Download these companies</button>
                            </div>
                        </ng-container>

                        <ng-template #elseBlock>
                            <p>{{ selectedCompanyCount }} companies are selected, but your account only has {{ userRemainingDownloads }} downloads remaining this billing period. Please select fewer companies.</p>
                        </ng-template>
                    </div>
                </div>

                <div class="row xsmall-margin-top" *ngIf="downloadUrl">
                    <div class="col-md-12">
                        <span>Your download has been generated and should start automatically. To start the download, please enable popups if you have not already.</span>
                    </div>
                </div>
                <div class="row xsmall-margin-top" *ngIf="downloadRequestId">
                    <div class="col-md-12">
                        <span>Your download has been queued and you will receive an email with a link when this is completed.</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
